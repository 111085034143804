import { PauseIcon, PlayIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

const transition = {
  duration: 0.75,
  repeat: Infinity,
  repeatDelay: 1.25,
};

function Rings() {
  return (
    <svg
      viewBox="0 0 500 500"
      fill="none"
      className={clsx(
        'col-start-1 row-start-1 size-full',
        '[mask-composite:intersect] [mask-image:linear-gradient(to_bottom,black_90%,transparent),radial-gradient(circle,rgba(0,0,0,1)_0%,rgba(0,0,0,0)_100%)]'
      )}
    >
      {Array.from(Array(42).keys()).map((n) => (
        <motion.circle
          variants={{
            idle: {
              scale: 1,
              strokeOpacity: 0.15,
            },
            active: {
              scale: [1, 1.08, 1],
              strokeOpacity: [0.15, 0.3, 0.15],
              transition: { ...transition, delay: n * 0.05 },
            },
          }}
          key={n}
          cx="250"
          cy="250"
          r={n * 14 + 4}
          className="stroke-black"
        />
      ))}
    </svg>
  );
}

function Button({ is_playing, onClick }) {
  return (
    <div className="z-10 col-start-1 row-start-1 flex items-center justify-center">
      <motion.div
        variants={{
          idle: { scale: 1 },
          active: {
            scale: [1, 1.05, 1],
            transition: { ...transition, duration: 0.75 },
          },
        }}
        className="flex size-24 items-center justify-center rounded-full bg-gradient-to-t from-blue-500 to-blue-300 shadow cursor-pointer"
        onClick={onClick}
      >
        {is_playing ? (
          <PauseIcon className="size-12 fill-white" />
        ) : (
          <PlayIcon className="pl-1 size-12 fill-white" />
        )}
      </motion.div>
    </div>
  );
}

const SampleAudio = () => {
  const [is_playing, set_is_playing] = useState(false);

  const [audio] = useState(
    new Audio(
      'https://guava-assets.s3.amazonaws.com/sample-audio-files/cathy.wav'
    )
  );

  // Add event listeners to update `is_playing` state
  useEffect(() => {
    const updatePlayingState = () => set_is_playing(!audio.paused);
    const handleAudioEnd = () => set_is_playing(false);

    audio.addEventListener('play', updatePlayingState);
    audio.addEventListener('pause', updatePlayingState);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      // Cleanup event listeners
      audio.removeEventListener('play', updatePlayingState);
      audio.removeEventListener('pause', updatePlayingState);
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, [audio]);

  const handle_click = () => {
    if (is_playing) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  return (
    <div className="mx-auto grid h-full grid-cols-1">
      <Rings />
      <Button is_playing={is_playing} onClick={handle_click} />
    </div>
  );
};

export default SampleAudio;