import Container from "./Container";
import { Heading, Subheading } from "./text";
import { investors } from "../data/investors"


import { Link } from "react-router-dom";




const Investors = () => {


  
  return (
    <Container className="mt-8">
      <div className="text-center">
        <Subheading>Backed by Silicon Valley legends</Subheading>
      </div>
      <div className="mt-8 flex justify-between items-center max-sm:mx-auto max-sm:max-w-md max-sm:flex-wrap max-sm:justify-evenly max-sm:gap-x-4 max-sm:gap-y-8">
        <Link to={investors.yc.url} target="_blank" className="col-span-2 max-h-14 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
          <img
            className="max-h-7 min-w-48 w-full object-contain"
            src={investors.yc.image}
          />
        </Link>

        <Link to={investors.kp.url} target="_blank" className="col-span-2 max-h-7 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
          <img
            className="max-h-7 min-w-48 w-full object-contain"
            src={investors.kp.image}
          />
        </Link>
        
        <Link to={investors.gfc.url} target="_blank" className="col-span-2 max-h-6 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
          <img
            className="max-h-6 min-w-24 w-full object-contain"
            src={investors.gfc.image}
          />
        </Link>
      </div>
    </Container>
  )
}





export default Investors;

