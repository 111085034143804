import { clsx } from 'clsx'

const Container = ({
  className,
  children,
} : {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div className={clsx(className, 'px-6 lg:px-8')}>
      <div className="mx-auto lg:mx-32 max-w-2xl lg:max-w-7xl">{children}</div>
    </div>
  )
}


export default Container;

