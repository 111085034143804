const esp_domains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "msn.com",
  "comcast.com",
  "icloud.com",
  "protonmail.com",
  "proton.me",
  "sky.com",
  "live.com",
  "verizon.net",
  "gmx.com",
  "att.net",
  "zohomail.com",
  "mail.com",
  "outlook.com",
]



export const validate_email_address = (email_address: string) => { 
  return /^[a-z0-9._%+-]+@(?:[a-z0-9-]+\.)+[a-z]{2,}$/.test(email_address)
}


export const validate_organization_domain = (domain: string) => { 
  if (esp_domains.includes(domain)) return false
  return /^((?!-)[a-z0-9-]{1,63}(?<!-)\.)+[a-z]{2,24}$/.test(domain)
}

export const validate_password = (password: string) => {
  return /^(?=.*[0-9])(?=.*[\W_])[a-zA-Z0-9\W_]{8,}$/.test(password)
}


export const validate_url = (url: string) => {
  return /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,6})(\/[^\s]*)?$/.test(url)
}


export const validate_phone_number = (phone_number: string) => {
  return /^\+?[1-9]\d{1,14}$/.test(phone_number)
}

export const validate_area_code = (area_code: string) => {
  return /^\d{3}$/.test(area_code)
}

export const validate_postal_code = (postal_code: string) => {
  return /^[1-9]\d{4}$/.test(postal_code)
}
