import channy_pic from "../media/team/channy.jpeg"
import ike_pic from "../media/team/ike.png"
import sandor_pic from "../media/team/sandor.jpg"


export const team = [
  {
    name: 'Channy Hong',
    role: 'Co-Founder / CEO',
    image: channy_pic,
    bio: 'Channy is an experienced AI researcher and a multi-time founder. He studied computer science at Harvard.',
    linkedinUrl: 'https://www.linkedin.com/in/channyhong/',
  },
  // {
  //   name: 'Ike Jin Park',
  //   role: 'Co-Founder / COO',
  //   image: ike_pic, 
  //   bio: 'Ike studied economics and philosophy at Harvard and loves talking to users about their problems and issues.',
  //   linkedinUrl: 'https://www.linkedin.com/in/ikejinpark/',
  // },
  {
    name: 'Sandor Palfy',
    role: 'Advisor',
    image: sandor_pic,
    bio: 'Sandor was previously the Chief Technology Officer of LogMeIn where he oversaw products such as LastPass, LogMeIn Central, and LogMeIn Pro.',
    linkedinUrl: 'https://www.linkedin.com/in/sandorpalfy/',
  },
]