import { motion } from "framer-motion";
import Container from "./Container";
import { LogoTimeline } from "./LogoTimeline";
import { Heading, Subheading } from "./text";
import clsx from "clsx";
import { BentoCard } from "./BentoCard";
import LogoCluster from "./LogoCluster";
import SampleAudio from "./SampleAudio";





const Highlights = () => {

  return (
    <div className="overflow-hidden pt-32">
      <Container>
        <div>
          <Subheading>Highlights</Subheading>
          <Heading as="h3" className="mt-2">
            Introducing Guava.
          </Heading>
        </div>
      </Container>
      <div className="mt-8 px-4 sm:px-24">
        <div 
          // className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2"
          className="mt-4 grid gap-4 sm:mt-8 lg:grid-cols-3 p-1"
        >
          

          {/* Unlimited 24/7 */}
          <BentoCard
            eyebrow="What Guava does"
            title="Unlimited call handling, 24/7"
            description="Guava's AI receptionists can handle unlimited number of calls simultaneously 24/7, increasing revenue and enhancing customer experience at fraction of the cost."
            graphic={<LogoCluster />}
            fade={['top']}
            // className="relative lg:row-span-2 "
            className=""
          />

          {/* Professional yet natural */}
          <BentoCard
            eyebrow="How Guava sounds"
            title="Professional yet natural"
            description="Guava's AI receptionists sound professional, engaged, and natural."
            graphic={<SampleAudio />}
            fade={['top']}
            // className="relative max-lg:row-start-1 "
            className=""
          />

          {/* For your business */}
          {/* <BentoCard
            eyebrow="Source"
            title="For your business"
            description="Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi."
            graphic={<LogoTimeline />}
            fade={['top']}
            className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2 "
          /> */}

          {/* A */}
          {/* <BentoCard
            eyebrow="How Guava works"
            title="For your business"
            description="Customize the AI receptionist to your specific business operations. Integrate with your existing tools."
            graphic={null}
            fade={['top']}
            // className="relative lg:row-span-2 "
            className=""
          /> */}


          <BentoCard
            eyebrow="How Guava works"
            title="For your business"
            description="Customize the AI receptionist to your specific business operations. Integrate with your existing tools."
            graphic={<LogoTimeline />}
            fade={['top']}
            // className="relative lg:row-span-2 "
            className="z-10 !overflow-visible"
          />

        </div>
      </div>
    </div>
  )
}




const DEP_Highlights = () => {

  return (
    <div className="overflow-hidden pt-32">
      <Container>
        <div>
          <Subheading>Highlights</Subheading>
          <Heading as="h3" className="mt-2">
            Introducing Guava.
          </Heading>
        </div>
      </Container>
      <div className="mt-8 px-4 sm:px-24">
        <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
          
          {/* Unlimited 24/7 */}
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                  Unlimited call handling, 24/7
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Guava's AI receptionists can handle unlimited number of calls simultaneously 24/7, increasing revenue and enhancing customer experience at fraction of the cost.
                </p>
              </div>
              <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                  <img
                    className="size-full object-cover object-top"
                    src="https://tailwindui.com/plus/img/component-images/bento-03-mobile-friendly.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
          </div>
          
          {/* How it sounds */}
          <div className="relative max-lg:row-start-1">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Professional yet natural</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Guava's AI receptionists sound professional, engaged, and natural.
                </p>
              </div>
              <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                <img
                  className="w-full max-lg:max-w-xs"
                  src="https://tailwindui.com/plus/img/component-images/bento-03-performance.png"
                  alt=""
                />
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
          </div>
          
          {/* For your business */}
          {/* <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
            <div className="absolute inset-px rounded-lg bg-white"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">For your business</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi.
                </p>
              </div>
              <LogoTimeline />
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
          </div> */}

          <motion.div
            initial="idle"
            whileHover="active"
            variants={{ idle: {}, active: {} }}
            className={clsx(
              "relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2",
              'group relative flex flex-col overflow-hidden rounded-lg',
              'bg-white ',
              'data-[dark]:bg-gray-800 data-[dark]:ring-white/15',
            )}
          >
            <div className="absolute inset-px rounded-lg bg-white"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">For your business</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi.
                </p>
              </div>
              {/* <LogoTimeline /> */}
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>

            {/* <div className="absolute z-40">
              <LogoTimeline />
              {["top"].includes('top') && (
                <div className="absolute inset-0 bg-gradient-to-b from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
              )}
              {["bottom"].includes('bottom') && (
                <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
              )}
            </div> */}
          </motion.div>



          
          {/* How it works */}
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                  How it works
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Customize the AI receptionist to your specific business operations. Integrate with your existing tools.
                </p>
              </div>
              <div className="relative min-h-[30rem] w-full grow">
                <div className="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl">
                  <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                    <div className="-mb-px flex text-sm/6 font-medium text-gray-400">
                      <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                        NotificationSetting.jsx
                      </div>
                      <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                    </div>
                  </div>
                  <div className="px-6 pb-14 pt-6">
                    {/* Your code example */}
                  </div>
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
          </div>



        </div>
      </div>
    </div>
  )
}





export default Highlights;

