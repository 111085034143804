import { Outlet } from "react-router-dom"

import Navbar from "../components/Navbar";
import { ChevronRightIcon } from '@heroicons/react/16/solid'
import { Link } from "react-router-dom"
import Footer from "../components/Footer";





const Layout = ({
  set_demo_modal_is_open
} : {
  set_demo_modal_is_open : any
}) => {


  
  return (
    <>
      {/* <Navbar set_demo_modal_is_open={set_demo_modal_is_open} /> */}
      <Navbar
        banner={
          <Link
            to="https://blog.guavahq.com/guava-preseed-announcement"
            className="flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white data-[hover]:bg-fuchsia-950/30"
          >
            Guava raises $1.2M from Y Combinator, Kleiner Perkins ...
            <ChevronRightIcon className="size-4" />
          </Link>
        }
        set_demo_modal_is_open={set_demo_modal_is_open}
      />
      <Outlet />
      <Footer 
        set_demo_modal_is_open={set_demo_modal_is_open}
      />
    </>
  )
}

export default Layout;