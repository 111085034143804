'use client'

import * as Headless from '@headlessui/react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { clsx } from 'clsx'
import {
  MotionValue,
  motion,
  useMotionValueEvent,
  useScroll,
  useSpring,
  type HTMLMotionProps,
} from 'framer-motion'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import useMeasure, { type RectReadOnly } from 'react-use-measure'
import Container from './Container'
import { Heading, Subheading } from './text'
import { Link } from 'react-router-dom'

import abstract_image_0 from "../media/graphics/abstract_0.png"
import abstract_image_1 from "../media/graphics/abstract_1.png"
import abstract_image_2 from "../media/graphics/abstract_2.png"
import abstract_image_3 from "../media/graphics/abstract_3.png"
import abstract_image_4 from "../media/graphics/abstract_4.png"



const testimonials = [
  {
    img: abstract_image_0,
    position: 'Operations Manager',
    organization: 'Orthopedics Clinic',
    quote: 
      `I was a bit skeptical about the idea of an AI receptionist at first, but Guava's demo quickly changed my mind on how professional it is and how revolutionary this can be for our clinic. Our AI receptionist sounds very natural, but to me it still sounds a bit “AI”. But this is actually a huge benefit because our clients feel really at ease talking to an intelligent agent that is infinitely patient and understanding. There was a clear business use case for us in using Guava to capture additional revenue and reduce costs, so it was a no brainer for us.`,
  },
  {
    img: abstract_image_2,
    position: 'Business owner',
    organization: 'Hair salon',
    quote:
      `Guava has been a game changer for our business. In the first month, we saw around 20% increase in appointments booked mainly by capturing calls coming in after business hours, mostly from working professionals who don't have time to schedule appointments during the day.`,
  },
  {
    img: abstract_image_4,
    position: 'Head of Call Center Operations',
    organization: 'University Medical Center',
    quote:
      `Our call center had huge issues with headcount and call drop rates, until Guava came to our rescue! We were able to spin up trained AI receptionists through Guava in days, and it's actually pretty incredible how well this is working for us. In a few months, we were able to decrease call drop rates by 3x while also reducing costs by 60% and that's not counting the high employee churn we had earlier and the cost to train new receptionists.`,
  },
  {
    img: abstract_image_1,
    position: 'Front Office Manager',
    organization: 'Veterinary Clinic',
    quote:
      `Guava has been an A+ experience for us so far. We love how customizable the AI receptionist can be, because it was very important for us to be able to have our receptionist be not only professional but also empathetic and patient when handling calls. Our customers would occasionally mention how surprised they were to be greeted by an AI receptionist but even more so by how smooth and 'fitting' the experience was.`,
  },
  {
    img: abstract_image_3,
    position: 'Business owner',
    organization: 'Thai Spa',
    quote:
      `Being able to take in calls 24/7 has been a game changer for us. We had noticed that we were getting a lot of calls from clients who would call us after business hours (where it just went to voicemail) and now with Guava, we're able to actually turn them into appointments. Our assistants are also now able to focus more on their actual customer-facing roles at the salon rather than being glued to the phone all day.`,
  },
]

const TestimonialCard = ({
  position,
  organization,
  img,
  children,
  bounds,
  scrollX,
  ...props
}: {
  img: string
  position: string
  organization: string
  children: React.ReactNode
  bounds: RectReadOnly
  scrollX: MotionValue<number>
} & HTMLMotionProps<'div'>) => {
  let ref = useRef<HTMLDivElement | null>(null)

  let computeOpacity = useCallback(() => {
    let element = ref.current
    if (!element || bounds.width === 0) return 1

    let rect = element.getBoundingClientRect()

    if (rect.left < bounds.left) {
      let diff = bounds.left - rect.left
      let percent = diff / rect.width
      return Math.max(0.5, 1 - percent)
    } else if (rect.right > bounds.right) {
      let diff = rect.right - bounds.right
      let percent = diff / rect.width
      return Math.max(0.5, 1 - percent)
    } else {
      return 1
    }
  }, [ref, bounds.width, bounds.left, bounds.right])

  let opacity = useSpring(computeOpacity(), {
    stiffness: 154,
    damping: 23,
  })

  useLayoutEffect(() => {
    opacity.set(computeOpacity())
  }, [computeOpacity, opacity])

  useMotionValueEvent(scrollX, 'change', () => {
    opacity.set(computeOpacity())
  })

  return (
    <motion.div
      ref={ref}
      style={{ opacity }}
      {...props}
      className="relative flex aspect-[9/18] w-72 shrink-0 snap-start scroll-ml-[var(--scroll-padding)] flex-col justify-end overflow-hidden rounded-3xl sm:aspect-[3/5] sm:w-96"
    >
      {/* Background Image */}
      {/* <img
        src={img}
        className="absolute inset-x-0 top-0 h-full w-full object-cover"
      /> */}

      {/* Transparent Overlay */}
      {/* <div className="absolute inset-0 bg-white opacity-10" /> */}

      {/* Content */}
      <div
        aria-hidden="true"
        // className="absolute inset-0 rounded-3xl bg-gradient-to-t from-white from-[calc(7/16*100%)] ring-1 ring-inset ring-gray-950/10 sm:from-25%"
        // className="absolute inset-0 rounded-3xl bg-gradient-to-t from-black from-[calc(7/16*100%)] ring-1 ring-inset ring-gray-950/10 sm:from-25%"
        className="absolute inset-0 rounded-3xl bg-gray-800 ring-1 ring-inset ring-gray-950/10 sm:from-25%"
        // className="absolute inset-0 rounded-3xl ring-1 ring-inset ring-gray-950/10"
      />
      <figure className="relative p-10 text-gray-100">
        <blockquote>
          <p className="relative text-sm/5 sm:text-lg/7 font-medium">
            <span aria-hidden="true" className="absolute -translate-x-full">
              “
            </span>
            {children}
            <span aria-hidden="true" className="absolute">
              ”
            </span>
          </p>
        </blockquote>
        <figcaption className="mt-6 border-t border-white/20 pt-6">
          <p className="text-sm/6 font-medium">{position}</p>
          <p className="text-sm/6 font-medium">
            <span
              // className="bg-gradient-to-r from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] bg-clip-text text-transparent"
              className=""
            >
              {organization}
            </span>
          </p>
        </figcaption>
      </figure>
    </motion.div>
  )
}

const CallToAction = () => {
  return (
    <div>
      <p className="max-w-sm text-sm/6 text-gray-600">
        Join the best sellers in the business and start using Radiant to hit
        your targets today.
      </p>
      <div className="mt-2">
        <Link
          to="#"
          className="inline-flex items-center gap-2 text-sm/6 font-medium text-pink-600"
        >
          Get started
          <ArrowLongRightIcon className="size-5" />
        </Link>
      </div>
    </div>
  )
}

const Testimonials = () => {
  let scrollRef = useRef<HTMLDivElement | null>(null)
  let { scrollX } = useScroll({ container: scrollRef })
  let [setReferenceWindowRef, bounds] = useMeasure()
  let [activeIndex, setActiveIndex] = useState(0)

  useMotionValueEvent(scrollX, 'change', (x) => {
    setActiveIndex(Math.floor(x / scrollRef.current!.children[0].clientWidth))
  })

  const scrollTo = (index: number) => {
    let gap = 32
    let width = (scrollRef.current!.children[0] as HTMLElement).offsetWidth
    scrollRef.current!.scrollTo({ left: (width + gap) * index })
  }

  return (
    <div className="overflow-hidden pt-24 sm:py-32">
      <Container>
        <div ref={setReferenceWindowRef}>
          <Subheading>What our users say</Subheading>
          <Heading as="h3" className="mt-2">
            Trusted by clinics & businesses.
          </Heading>
        </div>
      </Container>
      <div
        ref={scrollRef}
        className={clsx([
          'mt-8 sm:mt-16 flex gap-8 px-[var(--scroll-padding)]',
          '[scrollbar-width:none] [&::-webkit-scrollbar]:hidden',
          'snap-x snap-mandatory overflow-x-auto overscroll-x-contain scroll-smooth',
          '[--scroll-padding:max(theme(spacing.6),calc((100vw-theme(maxWidth.2xl))/2))] lg:[--scroll-padding:max(theme(spacing.8),calc((100vw-theme(maxWidth.7xl))/2))]',
        ])}
      >
        {testimonials.map(({ img, position, organization, quote }, testimonialIndex) => (
          <TestimonialCard
            key={testimonialIndex}
            position={position}
            organization={organization}
            img={img}
            bounds={bounds}
            scrollX={scrollX}
            onClick={() => scrollTo(testimonialIndex)}
          >
            {quote}
          </TestimonialCard>
        ))}
        <div className="w-[42rem] shrink-0 sm:w-[54rem]" />
      </div>
      <Container className="mt-16">
        <div className="flex justify-center">
          {/* <CallToAction /> */}
          <div className="hidden sm:flex sm:gap-2">
            {testimonials.map(({ position }, testimonialIndex) => (
              <Headless.Button
                key={testimonialIndex}
                onClick={() => scrollTo(testimonialIndex)}
                data-active={
                  activeIndex === testimonialIndex ? true : undefined
                }
                aria-label={`Scroll to testimonial from ${position}`}
                className={clsx(
                  'size-2.5 rounded-full border border-transparent bg-gray-300 transition',
                  'data-[active]:bg-gray-400 data-[hover]:bg-gray-400',
                  'forced-colors:data-[active]:bg-[Highlight] forced-colors:data-[focus]:outline-offset-4',
                )}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Testimonials;