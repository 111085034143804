import { useEffect, useState } from 'react'

import { useNavigate, Link } from "react-router-dom"


import { investors } from "../../data/investors"
import { team } from "../../data/team"

import Background from '../../components/Background'
import { mixpanel_client_track } from '../../libs/mixpanelClient'
import Container from '../../components/Container'
import Button from '../../components/Button'
import ReactLoading from 'react-loading'
import { Heading, Lead } from '../../components/text'
import Investors from '../../components/Investors'




import abstract_image_0 from "../../media/graphics/abstract_0.png"
import abstract_image_1 from "../../media/graphics/abstract_1.png"
import abstract_image_2 from "../../media/graphics/abstract_2.png"
import abstract_image_3 from "../../media/graphics/abstract_3.png"
import abstract_image_4 from "../../media/graphics/abstract_4.png"




const contacts = [
  { id: 1, value: 'support@guavahq.com', name: 'Support', bg: abstract_image_0 },
  { id: 2, value: 'channy@guavahq.com', name: 'Investor relations', bg: abstract_image_1 },
  { id: 3, value: 'careers@guavahq.com', name: 'Careers', bg: abstract_image_2 },
  { id: 4, value: 'info@guavahq.com', name: 'Questions', bg: abstract_image_3 },
  // { id: 5, value: '169 Madison Ave #2237, New York, NY 10016', name: 'Address', bg: abstract_image_4 },
]






const Team = () => {

  



  return (
    <Container className="mt-32">
      <Heading as="h1">Our executive team.</Heading>
      {/* <Lead className="mt-6 max-w-3xl">
        We're on a mission to transform revenue organizations by harnessing vast
        amounts of illegally acquired customer data.
      </Lead> */}
      <ul role="list" className="mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
        {team.map((person) => (
          <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
            <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src={person.image} alt="" />
            <div className="max-w-xl flex-auto">
              <h3 className="text-lg/6 font-semibold text-gray-600 tracking-tight">{person.name}</h3>
              <p className="font-normal text-sm/6 text-gray-600">{person.role}</p>
              <p className="mt-6 font-normal text-sm/6 text-gray-600">{person.bio}</p>
              <ul role="list" className="mt-6 flex gap-x-6">
                <li>
                  <Link to={person.linkedinUrl} target="_blank" className="text-gray-900 hover:text-gray-800 rounded-xl">
                    <span className="sr-only">LinkedIn</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  )
}



const Contact = () => {

  
  return (
    <div id="contact" className="my-32">

      {/* Header */}
      <Container className="">
        <Heading as="h1">Contact us.</Heading>
      </Container>

      {/* Cards */}
      <div className="sm:px-24 mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-2">
        {/* Stats Content */}
        {contacts.map((contact) => (
          <div key={contact.id} className="relative flex flex-col sm:m-4 z-10 bg-opacity-70 rounded-2xl overflow-hidden mx-4 my-2 sm:m-4 px-4 py-4 sm:py-12">
            
            {/* Background Image */}
            <img
              src={contact.bg}
              className="absolute inset-0 w-full h-full object-cover z-0"
              alt="Background"
            />

            {/* Transparent Overlay */}
            <div className="absolute inset-0 bg-white opacity-60 z-[1]" />
            
            {/* Name */}
            <div className="order-first text-base sm:text-lg font-medium tracking-tight text-gray-900 pb-4 z-10">
              {contact.name}
            </div>

            {/* Value */}
            <Link to={`mailto:${contact.value}`} target="_blank" className="text-sm/6 sm:text-base/6 font-normal text-gray-600 z-10">
              {contact.value}
            </Link>
          </div>
        ))}
      </div>
    </div>
    
  )
}






const AboutPage = ({
  demo_is_active,
  set_demo_modal_is_open
} : {
  demo_is_active : any
  set_demo_modal_is_open : any
}) => {


  // Navigate
  const navigate = useNavigate()


  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("www_about_visited", null)
    
  }, [])

  


    

  return (
    <div id="top" className="">

      {/* Hero */}
      <div className="relative isolate overflow-hidden">
        <div className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5 overflow-hidden bg-gray-100">
          {/* <Gradient className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5" /> */}
          <Background className="" demo_is_active={demo_is_active} sphere_position={"hero_about"} />
        </div>
        <Container className="relative py-32 md:py-0">
          <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 md:pb-48 md:pt-72 flex flex-col items-center">
            <h1 className="font-display text-balance text-6xl/[0.9] font-medium tracking-tight text-gray-950 sm:text-8xl/[0.8] md:text-8xl/[0.8] text-center">
              Introducing Guava.
            </h1>
            <p className="mt-8 max-w-lg text-xl/7 font-medium text-gray-950/75 sm:text-2xl/8 text-center">
              We are on a mission to usher in the new era of front desk operations.
            </p>
            <div className="mt-12 flex flex-col gap-x-6 gap-y-4 sm:flex-row justify-center">
              <Button to="https://cal.com/guava/get-started" target="_blank" >Get started</Button>
              <Button variant="secondary" onClick={() => set_demo_modal_is_open(true)}>
                {/* {demo_is_active ? `Call in progress` : `Try calling (959) 600-xxxx`} */}
                {demo_is_active ? (
                  <>
                    <span>
                      {`Call in progress`}
                    </span>
                    <div className="ml-2">
                      <ReactLoading
                        type='spokes'
                        color='#000000'
                        height={20}
                        width={20}
                      />
                    </div>
                  </>
                ) : (
                  <span>
                    {`Try calling (959) 600-xxxx`}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Container>
      </div>

      {/* Investors */}
      <Investors />

      {/* Team */}
      <Team />

      {/* Contact */}
      <Contact />
      
    </div>
  )
}



export default AboutPage